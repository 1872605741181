<template>
  <div class="home">

    <el-page-header @back="goBack" title="退出" :content="name">
    </el-page-header>
    <br>



    <br>

    <div>

      <el-button-group>
        <el-button :type="map.mode == 1 ? 'primary' : ''" @click="set_mode(1)">修图</el-button>
        <el-button :type="map.mode == 2 ? 'primary' : ''" @click="set_mode(2)">原图</el-button>
       
      </el-button-group>
      <el-date-picker v-model="map.date_range" type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
        placeholder="选择日期">
      </el-date-picker>

      <el-button icon="el-icon-search" circle @click="do_find"></el-button>
    </div>
    <br>

    <div class="list">
      <div class="itm" v-for="(vo, i) in list" :key="i">
        <img :src="map.mode == 2 ? vo.org_s : vo.truing_s" alt="" @click="show_one(i)">
        <div>
          {{ vo.cr22 }}
        </div>
        <div>
          {{ vo.job.group_identifier }}
        </div>
      </div>
    </div>
    <el-dialog title="大图" :visible.sync="dialogVisible" width="80%">
      <div class="tc" v-if="dialogVisible">
        <img :src="map.mode == 2 ? row.org_s : row.truing_s" alt="">

        <div>
          {{ row.cr22 }}
        </div>
        <div>
          {{ row.job.group_identifier }}
        </div>
        <a class="down" :href="row.cr2" target="__bank"> 下载cr2</a>
        <div>
          选择替换文件
        </div>
         <input type="file" @change="cover">

      </div>
    </el-dialog>











    <hr>
 <div @click="f5">加载更多</div>


  </div>
</template>

<script>

const OSS = require('ali-oss');
const client = new OSS({
  // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
  region: 'oss-cn-beijing',
  // 从环境变量中获取访问凭证。运行本代码示例之前，请确保已设置环境变量OSS_ACCESS_KEY_ID和OSS_ACCESS_KEY_SECRET。
  accessKeyId: 'LTAI5t6AwavozvtddTBQZ6pP',
  accessKeySecret: 'mdqM4nuikHAPcA10jNSIVwlHKsubTt',
  // 填写Bucket名称。例如examplebucket。
  bucket: 'chaoyunphoto',
  // 设置是否启用HTTPS。设置secure为true时，表示启用。
  // secure: true
})

async function isExistObject(name, options = {}) {
  try {
    await client.head(name, options);
    console.log('文件存在')
  } catch (error) {
    if (error.code === 'NoSuchKey') {
      console.log('文件不存在')
    }
  }
}



// copySmallObjecInSameBucket()
// isExistObject('audit/partner/达咔北京/圆明园店/发送订单/230929/正常/2412740-1/01L-/IMG_7072-01L1.jpg')
export default {
  name: 'HomeView',
  components: {

  },
  data() {
    return {
      list: [],
      listLoading: true,
      i: -1,
      id: -1,
      row: {},
      dialogVisible: false,
      total: 0,
      per_page: 0,
      type: 1,
      name: '',

      map: {
        ppdsize : 50,
        rmd: 1,
        mode: 1,
        date_range: ['2023-10-01', '2023-10-02'],
        page: 1,
      },
      show: {
        card_name: '',
        number: '',
        exp: '',
        cvv: '',
        bank: '',
      },
      ws: null,
      ws_live: true,
      info: { id: 0, },
      ft_time: null,
      ffile : '',
    }
  },
  created() {
    if (!localStorage.getItem('token')) {
      this.$router.replace({ path: '/login' })
    } else {

      this.f5()
    }

  },
  beforeDestroy() {
    this.ws_live = false
  },
  methods: {
    async deleteObject() {
  try {
    // 填写Object完整路径。Object完整路径中不能包含Bucket名称。
    let result = await client.delete(this.row.img_name);
    console.log(result);
    this.put()
  } catch (error) {
    console.log(error);
  }
},
    async copySmallObjecInSameBucket() {
       
      try {
        // 填写拷贝后和拷贝前的文件完整路径。文件完整路径中不能包含Bucket名称。
        // 设置目标文件的HTTP头和自定义目标文件的元信息。
        const result = await client.copy(this.rename(this.row.img_name),
        this.row.img_name);
        console.log(result);
        this.deleteObject()
      } catch (e) {
        console.log(e);
      }
    },

    async put() {
      try {
        // 填写OSS文件完整路径和本地文件的完整路径。OSS文件完整路径中不能包含Bucket名称。
        // 如果本地文件的完整路径中未指定本地路径，则默认从示例程序所属项目对应本地路径中上传文件。
        const result = await client.put(this.row.img_name, this.ffile);
        console.log(result);
        this.dialogVisible = false
        this.$message({
          'message' : '替换成功',
        })
      } catch (e) {
        console.log(e);
      }
    },
    cover(e) {
      
      console.log(e)
     
      // this.deleteObject()
       
      const files = e.target.files;

      if (files && files[0]) {

        // 获取原文件名
        const fileName = files[0].name;

        console.log(fileName);


        console.log(files[0])
         this.ffile = files[0]
          this.copySmallObjecInSameBucket()
      }

    },
    do_find() {
      this.list = []
      this.map.rmd = this.map.mode
      this.map.page = 1
      this.f5()
    },
    set_mode(n) {
      this.map.mode = n

    },
    show_one(i) {
      this.i = i
      this.row = this.list[i]
      this.dialogVisible = true
    },
    goBack() {
      localStorage.setItem('token', '')
      this.$router.replace({ path: '/login' })
    },


    f5() {
      if (this.ft_time) {
        clearInterval(this.ft_time)
      }
      this.get({
        url: '/index/photo_list',
        method: 'post',
        data: this.map
      }).then(e => {
 
        this.listLoading = false;
         for (const vo of e.data.list) {
           this.list.push(vo)
         }
       
        this.map.page ++
      })
    }
  }
}
</script>


<style  scoped lang="scss"> .list {
   display: flex;
   justify-content: space-between;
   width: 100vw;
   flex-wrap: wrap;

   .itm {
     flex: 0 0 19%;

     img {
       width: 100%;
       height: auto;
     }
   }
 }

 .tc {
   img {
     width: 100%;
     height: auto;
   }
 }
</style> 