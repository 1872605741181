import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import request from '@/utils/request'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
 
Vue.use(ElementUI);

Vue.config.productionTip = false


Vue.prototype.get = request
Vue.prototype.rename = (imagePath) => {
  const currentTime = new Date().getTime();

   
  const newImagePath = imagePath.replace('.jpg', '__tm_'+currentTime + '.jpg');
  return newImagePath;
 
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
